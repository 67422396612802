import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Button, Card } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './booking.scss'
import PointToPoint from './PointToPoint';
import Hourly from './Hourly';
import VipMeetGreet from './VipMeetGreet';
import Point from '../../assets/images/point.png'
import PointGray from '../../assets/images/pointgray.png'
import Hour from '../../assets/images/hour.png'
import HourGray from '../../assets/images/hourgray.png'
import Vip from '../../assets/images/vip.png'
import VipGray from '../../assets/images/vipgray.png'
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants';
const StyledTabs = styled((props) => (
	<Tabs
		{...props}
		variant='scrollable'
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}

	/>
))({
	borderRadius: '0px',
	height: '10px',
	justifyContent: 'center',
	alignItems: 'center',
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		width: '100%',
		backgroundColor: '#EDBA37',
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontFamily: 'nunito',
		fontWeight: '700',
		borderRadius: 0,
		fontSize: theme.typography.pxToRem(12),
		marginRight: theme.spacing(0),
		color: '#333333',
		boxShadow: 0,
		height: "30px !important",
		margin: 0,
		//width: '33.3%',
		//padding: '2px',
		backgroundColor: '#F6F6F6',
		'&.Mui-selected': {
			color: '#fff',
			backgroundColor: '#EDBA37'
		},

	}),
);
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 2 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BookingDetails() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [value, setValue] = React.useState(0);

	const { bookingData } = useSelector(state => state.booking)


	useEffect(() => {
		if (bookingData) {
			if (bookingData.type === "hour") {
				setValue(1);
			}
			else if (bookingData.type === "point") {
				setValue(0)
			}
		}
	}, [bookingData])



	//const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY })


	const handleChange = (event, newValue) => {
		dispatch({
            type: BOOKING_DATA,
            payload:null
        })
		setValue(newValue);
	};

	return (
		<Grid sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginTop: 0, position: 'relative', borderRadius: 0 }}>
			<div style={{ }}>
				<StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<StyledTab icon={<img src={value === 0 ? Point : PointGray} height={25} />} label="POINT TO POINT"  {...a11yProps(0)} className='tabText' iconPosition="start" />
					<StyledTab icon={<img src={value === 1 ? Hour : HourGray}  height={25} />} label="HOURLY"  {...a11yProps(1)} className='tabText' iconPosition="start" />
					{/* <StyledTab icon={<img src={value === 2 ? Vip : VipGray} height={15} />} label="VIP MEET & GREET"  {...a11yProps(2)} className='tabText' iconPosition="start" /> */}
				</StyledTabs>
			</div>
			<Grid xs={12} md={12}  boxShadow={0} >
				<Box className="booking_box" sx={{ }} xs={6} md={6} >
					<TabPanel value={value} index={0}>
						<PointToPoint />
					</TabPanel>
					<TabPanel value={value} index={1} >
						<Hourly />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<VipMeetGreet />
					</TabPanel>
				</Box>

			</Grid>
		</Grid>
	);
}
