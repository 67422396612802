import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import FAQContent from './FAQContent';

const FAQ = () => {

    return (
        <Layout head={"FAQ"}>
            <FAQContent/>   
        </Layout>
    )
}

export default FAQ
