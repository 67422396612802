import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../assets/images/ugks_logo.png'
import DropDownMenu from './DropDownMenu';
import MenuDropDown from './MenuDropDown';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_USER } from '../Redux/constants/userConstants';
import { Avatar, Grid, Stack } from '@mui/material';
import { googleLogout } from '@react-oauth/google';
import { BOOKING_DATA, RESET_BOOKING } from '../Redux/constants/bookingConstants';

const drawerWidth = 240;
function TopMenu(props) {
	const { user } = useSelector(state => state.auth)
	// const dispatch = useDispatch();
	const locations = useLocation();

	const [navItems, setNavItems] = useState([])
	const [loginType, setLoginType] = useState(null)


	useEffect(() => {
		let items = null;
		if (user) {
			if (user?.role === "customer") {
				items = [
					{
						name: 'My Bookings',
						path: '/dashboard'
					},
					{
						name: 'My Profile',
						path: '/profile'
					}
				]
			}
			else if (user?.role === "agent") {
				items = [
					{
						name: 'My Bookings',
						path: '/dashboard'
					},
					{
						name: 'E-Wallet',
						path: '/wallet'
					},
					// {
					// 	name: 'TOP UP',
					// 	path: '/topup'
					// },
					{
						name: 'My Profile',
						path: '/profile'
					}
				]
			}
			else if (user?.role === "corporate") {
				items = [
					{
						name: 'My Bookings',
						path: '/dashboard'
					},
					{
						name: 'My Profile',
						path: '/profile'
					}
				]
			}

			setNavItems(items)
			let type = localStorage.getItem("loginType");
			setLoginType(type)
		}
	}, [user])


	const location = useLocation()
	const navigate = useNavigate()

	const dispatch = useDispatch()


	const { window, auth } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);



	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigatePage = async () => {

		dispatch({
			type: RESET_BOOKING
		})
		navigate(auth ? '/dashboard' : '/')

	}
	const logout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		await localStorage.removeItem("usercurrency")
		//signOut()
		navigate('/signin')
	}
	const drawer = (
		<Box sx={{ textAlign: 'center' }}>
			<img src={Logo} style={{ width: 153, height: 50, cursor: 'pointer' }} onClick={navigatePage} />
			<Divider />
			{auth && <List>
				{navItems && navItems?.map((item) => (
					<ListItem key={item.name} disablePadding className={location.pathname === item?.path ? 'active' : 'inactive'}>
						<ListItemButton sx={{ border: 0 }} onClick={() => navigate(item.path)}>
							<ListItemText primary={item.name} style={{ color: 'black', fontWeight: 'bold', border: 0 }} />
						</ListItemButton>
					</ListItem>
				))}
				{((user?.role === "agent" || user?.role === "corporate") && !user?.parent_id) && <ListItemText sx={{}}  ><DropDownMenu sx={{ width: "100%" }} menuname={"Settings"} activeMenu={5} /></ListItemText>}
				<ListItem key={'Logout'} disablePadding>
					<ListItemButton sx={{ border: 0 }} onClick={logout}>
						<ListItemText primary={'Logout'} style={{}} />
					</ListItemButton>
				</ListItem>

			</List>}
		</Box>
	);



	const googleLogout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		await localStorage.removeItem("usercurrency")
		//googleLogout()
		//signOut()
		navigate('/signin')
	}

	const container = window !== undefined ? () => window().document.body : undefined;




	return (

		<AppBar position="static" color="default" className="header-navbar">
			<Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 10, paddingRight: 10 }}>
				<Toolbar disableGutters >

					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ ml:'1', display: { md: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Box sx={{ flexGrow: 1, }}>
						<img src={Logo} style={{ width: 153, height: 50, cursor: 'pointer' }} onClick={navigatePage} />
					</Box>
					{auth && <Box className="usermenu" sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'row' } }}>
						{navItems?.map((item) => (
							<Button className="btn-menu" style={{ padding: 0 }} key={item.name} onClick={() => navigate(item?.path)}>
								<Typography className={location.pathname === item?.path ? 'active' : 'inactive'}>{item.name}</Typography>
							</Button>
						))}
						{user?.role !== "customer" && !user?.parent_id && <DropDownMenu menuname={"Settings"} activeMenu={5} />}
						{/* <MenuDropDown  menuname={"Settings"} activeMenu={5} /> */}
						{loginType === "form" && <span onClick={logout}><Typography className='inactive'>Logout</Typography></span>}
						{loginType === "google" && <span onClick={() => googleLogout()}><Typography className='inactive'>Logout</Typography></span>}
					</Box>}
					{auth && <Stack alignItems={"center"}>
						<div className='username_menu'>
							<Avatar src={user?.image} />
							<span className='headerstyle' style={{}}> {user?.name}	{(user?.role === "agent" || user?.role === "corporate") && (user?.companyinfo?.company_name === 'undefined' || undefined ? '' : user?.companyinfo?.company_name)}</span>
						</div>
					</Stack>}

					<Box component="nav">
						<Drawer
							container={container}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
							sx={{
								display: { sm: 'block', md: 'none' },
								'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
							}}
						>
							{drawer}
						</Drawer>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>



	);
}



export default TopMenu;
