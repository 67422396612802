import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from '../../components/InputField'
import { fontWeight } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TopBar from '../../components/TopBar'
import BackupIcon from '@mui/icons-material/Backup';
import InputIcon from '../../components/InputIcon';
import LogoMockUp from '../../assets/images/Logo_mockup.jpg'
//import FlagInput from '../../components/FlagInput'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import { getProfile, updatePassword, updateProfile } from '../../Redux/actions/profileActions';

import * as yup from "yup";
import BorderBox from '../../components/BorderBox';
const EditPassword = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { user } = useSelector(state => state.auth)
    const [datas, setDatas] = useState([])
    // useEffect(() => {
    //     let id = user?.id
    //     dispatch(getProfile(id))
    // }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { updatepasswordSuccess, error } = useSelector(state => state.profile)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (updatepasswordSuccess) {
            setOpen(true)

            dispatch({
                type: RESET_ERROR
            })
            //dispatch(getSliderLists())

        }

    }, [error, updatepasswordSuccess])

    const schema = yup.object({
        password: yup.string().required('Password is required').min(8, 'Password length should be at least 8 characters').max(30,'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password is Required"),

    }).required();

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode:'onChange'
    });

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("password", data.password);
        formData.append("userID", user.id);
        dispatch(updatePassword(formData))
    };


    return (

        <Grid xs={12} marginTop={2} justifyContent="center" alignItems={"center"}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Password updated Successfully
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ }}>
                    <BorderBox title="Change Password">
                        <Grid container spacing={0.5} flexDirection="row">
                            <Grid item xs={12} md={4}>
                                <InputField
                                    fieldLabel="Password"
                                    fieldType="password"
                                    fieldName="password"
                                    control={control}
                                    error={errors.password}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputField
                                    fieldLabel="Confirm Password"
                                    fieldType="password"
                                    fieldName="confirm_password"
                                    control={control}
                                    error={errors.confirm_password}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button
                                    type="submit"
                                    variant="filled"
                                    sx={{ boxShadow: 3 }}
                                    style={{ }}
                                >
                                    <Typography className='buttonText' >Save</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </BorderBox>

                </div>
            </form>

        </Grid>

    )
}

export default EditPassword
