import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'

import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const PrivacyPolicyContent = () => {


    return (

        <div>
            <Container>

                <Grid container spacing={2} className="container_topspace">
                    <Grid item md={12} xs={12} style={{}}>

                   
                        <div className="full_contentholder">
                        <h1>Privacy Policy for UGKS Limousine Pte Ltd</h1>
                            <h2 className="row_title">Introduction</h2>
                            <p className="row_para">This policy outlines the collection, use, and protection of personal information at UGKS Limousine Pte Ltd.</p>
                          

                            <h2 className="row_title">Collection of Personal Data</h2>
                          
                            <ul className="row_list">
                                <li>We collect personal data during the registration and booking process.</li>
                                <li>Data collected includes names, contact details, and payment information.</li>
                                
                                
                            </ul>

                            <h2 className="row_title">Use of Personal Data</h2>
                            <p className="row_para">Personal data is used to manage bookings, improve service offerings, and for marketing purposes, with user consent.</p>
                            
                            <h2 className="row_title">Data Sharing and Disclosure</h2>
                            <ul className="row_list">
                                <li>Personal data may be shared with third-party service providers under strict confidentiality agreements.</li>
                                <li>Data may be disclosed if required by law.</li>
                                
                                
                            </ul>
                          
                            
                            <h2 className="row_title">Cookies and Tracking Technologies</h2>

                            <ul className="row_list">
                                <li>Our website uses cookies to enhance user experience and gather usage statistics.</li>
                                <li>Users can adjust their browser settings to manage cookies.</li>
                                
                                
                            </ul>
                            <h2 className="row_title">Data Security</h2>

                            <ul className="row_list">
                                <li>We implement robust security measures to protect personal data.</li>
                                <li>In the event of a data breach, affected users will be notified as per regulatory requirements.</li>
                                
                                
                            </ul>
                            <h2 className="row_title">User Rights</h2>

                            <ul className="row_list">
                                <li>Users have the right to access, correct, or delete their personal data.</li>
                                <li>Requests for data access can be made through our customer service.</li>
                                
                                
                            </ul>
                            <h2 className="row_title">Data Retention</h2>

                            <ul className="row_list">
                                <li>Personal data is retained for as long as necessary to fulfill the purposes outlined in this policy.</li>
                               
                                
                                
                            </ul>
                            <h2 className="row_title">Changes to the Privacy Policy</h2>

                            <ul className="row_list">
                                <li>Changes will be communicated to users through our website and by email.</li>
                               
                                
                                
                            </ul>
                            <h2 className="row_title">Contact Information</h2>

                            <ul className="row_list">
                                <li>Questions about this policy can be directed to our privacy officer at info@ugkslimousine.com</li>
                               
                                
                                
                            </ul>

                            
                        </div>

                        
                        
                     

                       
                        
                        
                        
                        
                        
                    </Grid>


                </Grid>

            </Container>

        </div>

    )
}

export default PrivacyPolicyContent
