import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const CustomerForm = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [country, setCountry] = useState(null);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(150, 'Maximum character exceeded'),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required"),
    }).required();

    const { register, handleSubmit, control, reset, formState: { errors },setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            is_corporate: 1,
            phone: '',
            mobile: '',
            password: '',
            email: '',
            firstname: '',
            lastname: '',
            confirm_password: ''
        },
        mode: 'onChange'
    });
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (registerSuccess) {
            setOpen(true)
            reset();
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const onSubmit = (data) => {

        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("phone", country.phonenumber);
        formData.append("country_code", country.callingCode);
        formData.append("mobile", country.phonenumber);
        formData.append("password", data.password);
        formData.append("user_type", 3);
        formData.append("is_corporate", 1);
        //formData.append("userID", user?.id);

        dispatch(userRegister(formData))
    };
    return (

        <Grid container justifyContent="center" style={{ paddingTop: 30 }}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Customer successfully registered
                </Alert>
            </Snackbar>

            <Grid xs={12} md={7} padding='1%' sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>


                <form onSubmit={handleSubmit(onSubmit)}>
                    <BorderBox title="Customer Details" style={{marginTop:'-20%'}}>
                        <FormGroup >
                            <Grid container spacing={0.5} >
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="First Name"
                                        fieldName="firstname"
                                        control={control}
                                        error={errors.firstname}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Last Name"
                                        fieldName="lastname"
                                        control={control}
                                        error={errors.lastname}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Email Address"
                                        fieldName="email"
                                        control={control}
                                        error={errors.email}
                                        fieldType="email"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PhoneInputs
                                        fieldLabel="Phone"
                                        fieldName="phone"
                                        control={control}
                                        error={errors.phone}
                                        onChangePhone={changePhone}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Password"
                                        fieldName="password"
                                        control={control}
                                        error={errors.password}
                                        fieldType="password"
                                        placeholder='Password should contain atleast 8 characters'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        fieldLabel="Confirm Password"
                                        fieldName="confirm_password"
                                        control={control}
                                        fieldType="password"
                                        error={errors.confirm_password}
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '2%', paddingBottom: '5%' }}>
                        <Button type="submit" variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }}>
                            <Typography className='buttonText'>Register</Typography></Button>
                    </div>
                    </BorderBox>
                  
                </form>

            </Grid>
        </Grid>

    )
}

export default CustomerForm
