import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import contactus_sideimg from '../../assets/images/contactus_sideimg.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const ContactContent = () => {


    return (

        <div>
        <Container>

            <Grid container spacing={2} className="container_topspace">
                <Grid item md={6} xs={12}>

                    <div className="contactinfo_wrapper">
                        <h3 className="companyname_head">UGKS Limousine Services</h3>
                        
                            <div className="contact_address_holder">
                                <p>362 upper Paya leaber road<br />
                                    Da Jin factory building<br />
                                    #03-08 S(534963)</p>
                                <p>Phone: <a href="">+65 8897 3808</a></p>
                                <p>Email: <a className="" href="">info@ugkslimousine.com</a></p>

                            </div>
                       
                    </div>

                </Grid>
                <Grid item md={6} xs={12}>
                <div className="contactimg_wrapper">
                 <img src={contactus_sideimg} />
                 </div>
                </Grid>
                
            </Grid>

        </Container>
    
    </div>

    )
}

export default ContactContent
