import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { json, useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import aboutus_img1 from '../../assets/images/aboutus_img1.jpg'
import aboutus_img2 from '../../assets/images/aboutus_img2.jpg'
import aboutus_img3 from '../../assets/images/aboutus_img3.jpg'
import safety_first from '../../assets/images/safety-first.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const FAQContent = () => {
    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = React.useState(null);


    const contactUs = () => {
        setAnchorElNav(null);
        navigate('/contactus')
      };

    return (

        <div>
            <Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid container sx={{  }}>
                    <Grid xs={12} md={8} sx={{}} >
                        <div className="faq_accordion_holder">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="topic_question"
                            >
                                What services does UGKS Limousine offer?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>UGKS Limousine offers a comprehensive range of luxury transportation services, including airport transfers, corporate travel, special events transportation, city tours, and more. We specialize in providing premium chauffeured transportation solutions tailored to the needs of our clients.</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="topic_question"
                            >
                                What types of vehicles are available in your fleet?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Our diverse fleet includes a selection of luxurious vehicles such as sedans, SUVs, executive vans, and limousines, all meticulously maintained to ensure comfort, safety, and style. Whether you require transportation for individuals, small groups, or larger parties, we have the perfect vehicle to meet your needs.</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="topic_question"
                            >
                                Are your drivers licensed and experienced?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Yes, all our drivers are highly trained, licensed professionals with extensive experience in chauffeured transportation. They undergo rigorous background checks and receive ongoing training to ensure they meet our standards of professionalism, safety, and customer service excellence.</p>
                            </AccordionDetails>

                        </Accordion>

                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4-content"
                                id="panel4-header"
                                className="topic_question"
                            >
                                How can I make a reservation?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Making a reservation with UGKS Limousine is easy and convenient. You can book online through our website, or you can contact our reservations team directly via phone or email. Our friendly staff will assist you in choosing the right vehicle and scheduling your transportation according to your requirements.</p>
                            </AccordionDetails>

                        </Accordion>

                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                                className="topic_question"
                            >
                                Can I request additional amenities or special services?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Absolutely! We strive to accommodate all your needs and preferences to enhance your transportation experience. Whether you require complimentary Wi-Fi, refreshments, or specific arrangements for a special occasion, simply let us know, and we will do our best to fulfill your requests.</p>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6-content"
                                id="panel6-header"
                                className="topic_question"
                            >
                                What areas do you serve?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>UGKS Limousine provides transportation services in Singapore and its surrounding areas, including airport transfers to and from Changi Airport, corporate travel within the city, and transportation for special events and occasions.</p>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel7-content"
                                id="panel7-header"
                                className="topic_question"
                            >
                                What measures do you take to ensure passenger safety and comfort?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>At UGKS Limousine, passenger safety and comfort are our top priorities. Our vehicles are equipped with advanced safety features, and our drivers adhere to strict safety protocols at all times. Additionally, our vehicles undergo regular maintenance checks to ensure they are in optimal condition for every journey.</p>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel8-content"
                                id="panel8-header"
                                className="topic_question"
                            >
                                What payment methods do you accept?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>We accept various payment methods for your convenience, including credit/debit cards, bank transfers, and cash payments. Our billing process is transparent and straightforward, and we provide detailed invoices for all transactions.</p>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel9-content"
                                id="panel9-header"
                                className="topic_question"
                            >
                                Do you offer special rates for corporate clients or frequent travellers?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>Yes, we offer competitive rates and customizable packages for corporate clients, frequent travelers, and long-term contracts. Please contact our corporate services team for more information and to discuss your specific requirements.</p>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel10-content"
                                id="panel10-header"
                                className="topic_question"
                            >
                                How far in advance should I book my transportation ?
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>We recommend booking your transportation with UGKS Limousine as early as possible, especially for special events, peak travel seasons, or large group bookings. However, we understand that plans can change, and we strive to accommodate last-minute requests whenever possible.</p>
                                <p>If you have any other questions or require further assistance, please don't hesitate to contact us. Our dedicated team is here to help and ensure your transportation experience with UGKS Limousine is seamless and enjoyable.</p>
                            </AccordionDetails>

                        </Accordion>
                        </div>
                    </Grid>
                    <Grid xs={12} md={4} sx={{}} >
                        <div className="more_info_section">
                        <h3>If you need more help</h3>
                        <p>For any additional inquiries, please feel free to contact our customer service team. We look forward to providing you with exceptional transportation services for your next event!</p>
                        <Button type="submit" className="btn"
										sx={{ boxShadow: 2 }}
										variant="filled" style={{}}
                                        onClick={(contactUs)}
									>
                                        Contact Us</Button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>

    )
}

export default FAQContent
