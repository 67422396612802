import React, { Fragment, useEffect, useState } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import Grid from '@mui/material/Unstable_Grid2';
import { json, useLocation, useNavigate } from 'react-router-dom';
import SelectedVehicle from '../../components/SelectedVehicle'
import TopBar from '../../components/TopBar'
import { CircularProgress, Typography, Button } from '@mui/material';
import './style.scss'
import PrintImg from '../../assets/images/IconsNew/print.png'
import Mybooking from '../../assets/images/IconsNew/info.png'
import PrintIcon from '@mui/icons-material/Print';

import ArticleIcon from '@mui/icons-material/Article';
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_DATA, RESET_BOOKING } from '../../Redux/constants/bookingConstants';
import Lottie from "lottie-react";
import Checked from "../../assets/images/checked.json";
import { PlusOneOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { newBooking } from '../../Redux/actions/bookingActions';
import PrintReceipt from './PrintReceipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import { saveAs } from "file-saver";

const Done = ({ head }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const [booking, setBooking] = useState(null)
    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(false);

    const { orderData, bookingSuccess, couponDetails, couponApplied, bookingType, bookingResponse } = useSelector(state => state.booking)
    const { user } = useSelector(state => state.auth)

    const dispatch = useDispatch();


    // useEffect(() => {
    //     //setBooking(bookingData);
    //     //setVehicle(selectedVehicle);
    //     localStorage.removeItem("booking");
    //     setTimeout(() => {
    //         dispatch({
    //             type: RESET_BOOKING
    //         })
    //     }, 5000);

    // }, [])

    const newBooking = () => {
        dispatch({
            type: RESET_BOOKING
        })
        navigate('/newbooking');
    }
    const myBooking = () => {
        dispatch({
            type: RESET_BOOKING
        })
        navigate('/dashboard');
    }



    const downlodeTripInvoice = async (id) => {

        setLoading(true);
        await customAxios.post(`newinvoicegenerate`, {
            booking_id: id
        })
            .then(async response => {
                const url = response.data.data.replace('public_html/', '');
                saveAs(
                    url,
                    `reservation#${id}.pdf`
                );
                // const link = document.createElement('a');
                // link.href = url;
                // link.target = '_blank'
                // link.setAttribute('download', 'file.pdf'); //or any other extension
                // document.body.appendChild(link);
                // link.click();
                setLoading(false);
            })
            .catch(async error => {
                setLoading(false);
                toast.error(error)

            });


    }


    return (
        <Layout head={head}>
            <WorkFlow activeStep={5} mode={'ok'} />
            {vehicle && booking && <SelectedVehicle data={vehicle} booking={booking} />}
            <Grid flexDirection={{ xs: 'column', md: 'row' }} style={{ display: 'flex', justifyContent: 'center', paddingTop: '2%' }} marginBottom={2} >
                <Button variant="filled" style={{ borderRadius: 0, height: 40 }} >
                    <Lottie animationData={Checked} style={{ height: 50, width: 50 }} loop={true} /><span className='successText' >Your trip has been successfully booked !</span> </Button>
            </Grid>
           
            <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', overflow: 'hidden', paddingLeft: '1%' }}>
                <Button type="submit" variant="filled" onClick={() => newBooking()} endIcon={<Add style={{ color: 'white' }} />} style={{ backgroundColor: '#333333', borderRadius: 0, marginRight: 10, padding: 5, height: 28 }} >
                    <Typography className='buttonText'>NEW BOOKING</Typography>
                </Button>
                {/* <PDFDownloadLink
                    document={<PrintReceipt order={bookingResponse} user={user} />}
                    fileName="invoice"
                    style={{ color: '#333333' }}
                > */}
                <Button type="submit" variant="filled" endIcon={<img src={PrintImg} style={{ width: 25, height: 25 }} />} style={{ backgroundColor: '#333333', borderRadius: 0, padding: 5, height: 28, }} onClick={() => downlodeTripInvoice(bookingResponse?.booking?.id)} >
                    <Typography className='buttonText'>PRINT Invoice</Typography>
                </Button>
                {/* </PDFDownloadLink> */}
            </Grid>

            <Grid className="thanks_msg">
              
                <h3 className="tnks_msg_head"> Thank You for Choosing UGKS Limousine</h3>
             
                <Typography> At UGKS Limousine, we are dedicated to providing unparalleled luxury transportation services tailored to meet your needs and exceed your expectations. Whether you're traveling for business, attending a special event, or exploring the sights of Singapore, we are committed to ensuring your journey is comfortable, safe, and memorable.</Typography>

<h3 className="">Experience the Difference</h3>
<Typography>
With a diverse fleet of luxurious vehicles, professional chauffeurs, and personalized service, UGKS Limousine offers a level of sophistication and elegance unmatched in the industry. From our meticulously maintained vehicles to our attention to detail, every aspect of your transportation experience is carefully crafted to enhance your comfort and satisfaction.
</Typography>

<h3>Your Satisfaction Is Our Priority</h3>
<Typography>At UGKS Limousine, we prioritize your satisfaction above all else. From the moment you make your reservation to the completion of your journey, our dedicated team is here to assist you every step of the way. We pride ourselves on delivering exceptional customer service and ensuring that your transportation experience with us is seamless and stress-free.</Typography>


<h3>Book with Confidence</h3>
<Typography>When you choose UGKS Limousine, you can book with confidence knowing that you're in good hands. With our commitment to safety, reliability, and professionalism, you can trust us to take care of all your transportation needs, allowing you to focus on enjoying the journey and creating lasting memories.</Typography>


<h3>Contact Us Today</h3>
<Typography>Ready to experience the luxury and convenience of UGKS Limousine? Contact us today to make your reservation or inquire about our services. Our friendly and knowledgeable team is here to assist you and ensure that your transportation experience with us is nothing short of exceptional.</Typography>
<br/>
<Typography>Thank you for choosing UGKS Limousine. We look forward to welcoming you aboard and providing you with the ultimate transportation experience.</Typography>


                </Grid>
            {/* <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', overflow: 'hidden', paddingLeft: '1%', marginTop: 10 }}>
                <Button type="submit" variant="filled" onClick={() => myBooking()} endIcon={<img src={Mybooking} style={{ width: 25, height: 25 }} />  } style={{backgroundColor:'#333333',borderRadius:15,height:50,width:200,marginRight:'3%'}} >
                <Typography className='buttonText'>My Booking</Typography>
            </Button>
            </Grid> */}
        </Layout>
    )
}

export default Done
