import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import TripHistory from './TripHistory'
import { Button, Grid, Stack } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TopBar from '../../components/TopBar'
import { useNavigate } from "react-router-dom";
import TopMenu from '../../components/TopMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getTrip } from '../../Redux/actions/tripActions'
import CarImg from '../../assets/images/IconsNew/car.png'
import { AddCircle } from '@mui/icons-material'
import { RESET_BOOKING } from '../../Redux/constants/bookingConstants';
import { isEmpty, isNumber } from 'lodash'
const Dashboard = ({ head }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)

    const [subUser, setSubUser] = React.useState((user?.parent_id && isNumber(user?.parent_id)) ? true : false);
    const [createBooking, setCreateBooking] = React.useState(false);
    const [editBooking, setEditBooking] = React.useState(false);


    useEffect(() => {
      if(user?.parent_id && user?.parent_id > 0){
        let permissions = user?.rolesinfo?.roles_json?.permissions
        let bookings = permissions?.find((item) => item?.menu === 'My Bookings');
        if(bookings){
            setCreateBooking(bookings?.create?.selected ? true : false)
            setEditBooking(bookings?.edit?.selected ? true : false)
        }
        
      }
    
    }, [user])
    


    

    useEffect(() => {
        let params = {
            parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
            child_id : user?.id
        }
        
        //let id = user?.id
        dispatch(getTrip(params))
    }, [])

    useEffect(() => {
        dispatch({
            type: RESET_BOOKING
        })
      }, []);


    return (
        <Layout head={head}>
            {/* <TopBar activeMenu="1"/> 
            <TopMenu /> */}

            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} paddingTop={"40px"} >
                <h1 class='underline'>Trip History</h1>
                {/* <Grid display={{ xs: 'none', md: 'flex' }} style={{ flexDirection: 'row', justifyContent: 'center' }} >
                    <Button 
                        variant="contained" 
                        endIcon={<img src={CarImg} style={{width:25,height:25}} />}
                        style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 40 }}
                        onClick={() => navigate("/newbooking")} 
                        className="newbooking" 
                    >
                        NEW BOOKING
                    </Button>
                    
                </Grid> */}
                {(!subUser || (subUser && createBooking)) && <Grid display={{ xs: 'flex', md:'none' }}>
                    <AddCircle onClick={() => navigate("/newbooking")}  />
                </Grid>}
            </Stack>
            <TripHistory 
                createBooking={(!subUser || (subUser && createBooking)) ? true : false} 
                editBooking={(!subUser || (subUser && editBooking)) ? true : false}
            />
        </Layout>
    )
}

export default Dashboard
