import React, { useEffect } from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box } from '@mui/material'
import './style.scss'
import HomeTopBar from '../../components/HomeTopBar'
import Footer from '../../components/Footer'
import BookingDetails from '../Booking/BookingDetails'
import Hoursupport from '../../assets/images/24hour-support.png'
import Quality from '../../assets/images/quality.png'
import Gps from '../../assets/images/gps.png'
import Excellent from '../../assets/images/excellent.png'
import safe_secure from '../../assets/images/safe_secure.png'
import best_quality from '../../assets/images/best_quality.png'
import car from '../../assets/images/car.png'
import BookingForm from './BookingForm'
import { useDispatch, useSelector } from 'react-redux'
import { BOOKING_INPUT, COUPON_VERIFY_FAIL } from '../../Redux/constants/bookingConstants';
import { isEmpty } from 'lodash';
import { redirect, useNavigate } from 'react-router-dom'
const Home = () => {
    //process.env.REACT_APP_GOOGLE_KEY

    const navigation = useNavigate()

    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        deleteBooking()
    }, [user])

    const deleteBooking = () => {
      
        // let orderDatas = orderData.filter((or, i) => i !== index)
        let orderDatas ='';
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'orderData',
                value: orderDatas
            }
        })

    }
    useEffect(() => {
        if (user) {
            navigation('/dashboard')
        }
    }, [user])


    return (
        <div>
            <div className="mainHeader">
                <Container>
                    {/* <HomeHeader /> */}
                    <HomeTopBar activeMenu='1' />
                </Container>
            </div>
            <div className="homeBackground">
                <Grid container sx={{ height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap', alignItems: 'top' }}>
                    <Grid xs={12} md={12} sx={{}} >
                        <h1 className='heading' xs={{fontSize : '22px'}} >Enjoy your Life with our<br />Comfortable Cars</h1>
                    </Grid>
                    <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                        <BookingForm />
                    </Grid>

                </Grid>
            </div>

            <div className="bg-orange">

                <Container>
                    <Grid container spacing={2} id="sec-con">
                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box">

                                <div className="imges">
                                    <img src={Hoursupport} style={{ }} alt="Hour Support" />

                                </div>
                                <div className="sub-con">
                                    <h6>24 Hours Support</h6>
                                    <p>We support you <br /> all hours of the day.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box">
                                <div className="imges">
                                    <img src={Quality} style={{  }}  alt="Quality"/>

                                </div>
                                <div className="sub-con">
                                    <h6>Qualified Assurance</h6>
                                    <p>All cars have a <br /> valid insurance.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box">
                                <div className="imges">
                                    <img src={Gps} style={{ }}  alt="GPS & Cars" />

                                </div>
                                <div className="sub-con">
                                    <h6>GPS on Cars</h6>
                                    <p>All cars are equipped with <br /> GPS navigation system.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

            </div>
            <div className="why-chooseus">
                <Container className="container">
                    <h2 className="section_head text-center">Why choose Us</h2>
                    <Grid container spacing={2} className="cen">

                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box-3">
                            <div className="imges-3">
                                <img src={Excellent} alt="excellent" />
                            </div>
                            <h4>Excellent Service</h4>
                            <p>At the heart of our service philosophy is a deep commitment to putting our customers first.We
                                listen,understand.</p>
                                </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                        <div className="sub-box-3">
                            <div className="imges-3">
                                <img src={safe_secure} alt="safe_secure" />
                            </div>
                            <h4>Safe & Secure</h4>
                            <p>Our commitment to safety begins with our fleet of vehicles.Regular maintenance checks, stringent
                                inspections.</p>
                                </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                        <div className="sub-box-3">
                            <div className="imges-3">
                                <img src={best_quality} alt={best_quality} />
                            </div>
                            <h4>Best Quality</h4>
                            <p>our commitment to quality begins with our fleet of vehicles. Meticulously maintained and regularly
                                inspected.</p>
                                </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="perfect-ride bg-secondary">
                <Container className="container">
                    <Grid container className="row sec-pad">
                        <Grid item xs={12} md={7} sx={12} className="">
                            <p className="pre_head">PERFECT RIDES</p>
                            <h4 className="section_head">Best Travel Experience</h4>
                            <p className="para">Excellence is not a destination it's a journey. We are committed to continuours
                                improvement,regularly seeking feedback and implementing enhancements to elevate our
                                services.Your experience with us is at the forefront of our evolution.</p>
                            <ul>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Premium Quality</li>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Safe Journey</li>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Well Professional Drivers</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={5} sx={12} className="pos-rel">
                            <div className="empty-back">

                            </div>
                            <div className="car-img">
                                <img src={car} alt="image" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Home
