import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Box, MenuItem, Stack, IconButton, CircularProgress } from '@mui/material'
import InputField from '../../components/InputField'
import BackupIcon from '@mui/icons-material/Backup';
import { Image, Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { addTopUp, getBanks } from '../../Redux/actions/topupActions'
import ImageInput from '../../components/ImageInput';
import SelectInput from '../../components/SelectInput';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DateInput from '../../components/DateInput';
import DateOnlyInput from '../../components/DateOnlyInput';
import BorderBox from '../../components/BorderBox';
import FileInput from '../../components/FileInput';
import DropdownInput from '../../components/DropdownInput';

const TopUpForm = ({ heading, closeModal }) => {

    const dispatch = useDispatch();
    const [currencySymbol, setcurrencySymbol] = useState('S$');
    const { bankLists, newTopup, loading } = useSelector(state => state.topup)
  
    useEffect(() => {
        dispatch(getBanks())
       
    }, [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
    };

    const { user } = useSelector(state => state.auth)
    const [image, setImage] = useState(null);
    
    useEffect(() => {
        setcurrencySymbol(localStorage.getItem("usercurrency"));
       
    }, [])
    const schema = yup.object({
        bankID: yup.string().required('Bank Id required'),
        branch_name: yup.string().required('Branch name required'),
        amount: yup.string().required('Amount required'),
        deposit_date: yup.string().required('Deposit Date required').nullable(),
        reciept: yup.mixed().required('Receipt required'),
    }).required();

    const date = new Date();

    const { register, handleSubmit, setFieldValue, data, control, formState: { errors }, setValue, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            deposit_date: moment(date).format('YYYY-MM-DD')
        }

    });

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("userID", user?.parentDetails ? user?.parentDetails?.id : user?.id);
        formData.append("createdBy", user?.id);
        formData.append("amount", data.amount);
        formData.append("bankID", data.bankID);
        formData.append("branch_name", data.branch_name);
        formData.append("deposit_date", data.deposit_date);
        formData.append("user_type", user?.customer_type);
        formData.append("reciept", data.reciept);
        dispatch(addTopUp(formData, user?.id))
    };

    useEffect(() => {
        if (newTopup) {
            reset()
        }
    }, [newTopup])

    const bankChange = (e) => {
          
        let result = bankLists?.find((res)=>res?.id === e)
        if(result){
            setValue('branch_name',result?.bank_code)
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {heading && <div style={{ display: 'flex', padding: '1%', justifyContent: 'flex-end' }}>

                    <IconButton onClick={closeModal} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                        <CloseIcon />
                    </IconButton>
                </div>}
                <BorderBox title="TOP UP DETAILS">
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <DropdownInput
                                changeValue={(e) => {

                                    bankChange(e.target?.value)
                                }}
                                fieldLabel="Bank Name"
                                control={control}
                                fieldName="bankID"
                                error={errors.bankID}
                            >
                                {bankLists?.map((bank) => (
                                    <MenuItem key={bank.id} value={bank.id} className='formText'>
                                        {bank.bank}
                                    </MenuItem>
                                ))}
                            </DropdownInput>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <InputField
                            readonly={true}
                                fieldLabel="Branch Code"
                                fieldName="branch_name"
                                control={control}
                                error={errors.branch_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <InputField
                                fieldLabel={`Amount ${currencySymbol}`}
                                fieldName="amount"
                                control={control}
                                error={errors.amount}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <DateOnlyInput
                                onDateChange={(value) => {

                                    setValue('deposit_date', moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                                }}
                                fieldLabel="Deposit Date"
                                fieldName="deposit_date"
                                fieldType="date"
                                control={control}
                                error={errors.deposit_date}
                                maxDate={moment()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <ImageInput
                                fieldName="reciept"
                                fieldLabel="Upload Receipt"
                                imageHelperText={"512 * 512"}
                                control={control}
                                error={errors.reciept}
                                changeImage={(image) => {
                                    setValue("reciept", image)
                                    setError('reciept',)
                                }}
                                Icon={
                                    <div className='uploadIcon'><Image /></div>}
                            />

                            {/* <FileInput
                                fieldLabel="Itenary"
                                fieldName="itenary"
                                fieldType={"file"}
                                control={control}
                                error={errors.itenary}
                                // onChange={e => uploadFiles(e.target.files[0])}
                            /> */}

                        </Grid>

                    </Grid>
                </BorderBox>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type="submit"
                        variant="filled"
                        disabled={loading}
                        style={{ backgroundColor: '#333333', borderRadius: 4, height: 30, marginTop: 20 }}
                    >
                        <Typography className='buttonText'>{loading ? <CircularProgress style={{ fontSize: '10px', color: '#fff' }} /> : 'SAVE'} </Typography>
                    </Button>
                </div>
            </form>
        </Box>
    )
}

export default TopUpForm
